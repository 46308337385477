import { useEffect } from "react"

export function useBodyOverflowHidden(condition = true) {
  // disable scroll on body when modal is open
  useEffect(() => {
    document.body.style.overflow = condition ? "hidden" : "unset"
    return () => {
      document.body.style.overflow = "unset"
    }
  }, [condition])
}
