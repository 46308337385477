"use client"

import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react"
import { useClickAway } from "ahooks"
import { useMemo, useRef } from "react"
import { CloseButton } from "src/components/common/CloseButton"
import { MenuIcon } from "src/components/icons"
import { FooterSettings } from "src/components/layout/footer/FooterSettings"
import { HeaderLink } from "src/components/layout/header/HeaderLink"
import { A } from "src/components/unstyled/A"
import { features } from "src/config/features"
import { routes } from "src/config/routes"
import { useBodyOverflowHidden } from "src/hooks/useBodyOverflowHidden"
import { localizeText } from "src/localization/utils"
import { StorefrontCountry } from "src/types/storefront"
import { isTruthy } from "src/utilities/typescript"

export type SideMenuProps = {
  countryCode: string
  countries: StorefrontCountry[]
}

export function SideMenu({ countryCode, countries }: SideMenuProps) {
  const T = localizeText(text)
  const sideMenuItems = useMemo(() => {
    return [
      { href: routes.home, label: T.sideMenuItems.home },
      { href: routes.allProducts, label: T.sideMenuItems.products },
      { href: routes.search, label: T.sideMenuItems.search },
      features.account && { href: routes.accountRoot, label: T.sideMenuItems.account },
      { href: routes.cart, label: T.sideMenuItems.cart },
    ].filter(isTruthy)
  }, [T])

  return (
    <Popover>
      {({ open, close }) => {
        const ref = useRef<HTMLDivElement>(null)
        useBodyOverflowHidden(open)
        useClickAway(() => close(), ref)

        return (
          <>
            <PopoverButton aria-label={T.menu}>
              <HeaderLink href="" label={T.menu}>
                <MenuIcon size={24} />
              </HeaderLink>
            </PopoverButton>

            <PopoverPanel
              transition
              className="transition ease-out duration-150 backdrop-blur-2xl sm:p-4 absolute inset-0 z-50 flex flex-col w-screen h-screen text-sm data-[closed]:opacity-0"
            >
              <div
                ref={ref}
                className="bg-gray-900/65 sm:rounded-xl relative flex flex-col justify-between h-full max-w-md p-6 text-white"
              >
                <CloseButton onClick={close} />

                <div />

                <ul className="flex flex-col items-start justify-start gap-6">
                  {sideMenuItems.map(({ href, label }) => {
                    return (
                      <li key={href}>
                        <A href={href} onClick={close} className="hover:text-gray-200 text-h2 flex leading-10">
                          {label}
                        </A>
                      </li>
                    )
                  })}
                </ul>

                <FooterSettings countryCode={countryCode} countries={countries} />
              </div>
            </PopoverPanel>
          </>
        )
      }}
    </Popover>
  )
}

const de = {
  menu: "Menü",
  close: "Schließen",
  sideMenuItems: {
    home: "Startseite",
    products: "Alle Boxen",
    search: "Suche",
    account: "Konto",
    cart: "Warenkorb",
  },
}

const en: typeof de = {
  menu: "Menu",
  close: "Close",
  sideMenuItems: {
    home: "Home",
    products: "Alle Boxen",
    search: "Search",
    account: "Account",
    cart: "Cart",
  },
}

const text = { de, en }
