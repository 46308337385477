"use client"

import { FormEvent, useState } from "react"
import { GenericErrorMessage } from "src/components/common/GenericErrorMessage"
import { Input } from "src/components/common/Input"
import { PrimaryButton } from "src/components/common/PrimaryButton"
import { A } from "src/components/unstyled/A"
import { routes } from "src/config/routes"
import { contactStore, mergeContactData } from "src/integrations/lib/state"
import { localizeText } from "src/localization/utils"
import { addErrorContext, reportError } from "src/utilities/error"
import { action_subscribeToNewsletter } from "./actions"

export const NewsletterSignupForm = () => {
  const email = contactStore((state) => state.email)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const T = localizeText(text)

  function handleEmailChange(email: string) {
    mergeContactData({ email })
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault()

    try {
      setLoading(true)
      setSuccess(false)
      setError(false)

      addErrorContext("handleSubmit", { email })
      const result = await action_subscribeToNewsletter({ email: email || "" })

      if (!result.ok) {
        throw new Error(result.status + " " + result.statusText + ": " + result.body)
      }

      setSuccess(true)
    } catch (error) {
      reportError("Failed to sign up for newsletter", error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  if (success) {
    return (
      <div className="rounded-3xl bg-brand-secondary-lighter flex flex-col gap-2 px-4 py-8">
        <p className="font-bold">{T.successHeading}</p>
        <p>{T.successLine1}</p>
        <p>{T.successLine2}</p>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-8">
      <form
        onSubmit={handleSubmit}
        inert={loading}
        className="md:flex-row whitespace-nowrap flex flex-col items-center justify-center gap-4"
      >
        <Input
          type="email"
          name="email"
          autoComplete="home email"
          label="Deine E-Mail"
          required
          large
          value={email}
          onChange={(event) => handleEmailChange(event.target.value.trim())}
        />

        {error && <GenericErrorMessage />}

        <PrimaryButton type="submit" size="large" color="primary" loading={loading}>
          {T.submit}
        </PrimaryButton>
      </form>

      <div className="text-balance text-sm">{T.youAcceptThePrivacyPolicy}</div>
    </div>
  )
}

const de = {
  youAcceptThePrivacyPolicy: (
    <>
      Mit der Anmeldung zum Newsletter akzeptierst du die{" "}
      <A href={routes.privacy} className="whitespace-nowrap underline">
        Datenschutzbestimmungen
      </A>
      .
    </>
  ),
  submit: "Anmelden!",
  successHeading: "Fast geschafft!",
  successLine1: "Bestätige deine Anmeldung in der Email, die wir dir gesendet haben.",
  successLine2: "Du wirst bald von uns hören!",
}

const en: typeof de = {
  youAcceptThePrivacyPolicy: (
    <>
      By signing up for the newsletter you accept the{" "}
      <A href={routes.privacy} className="whitespace-nowrap underline">
        privacy policy
      </A>
      .
    </>
  ),
  submit: "Sign up!",
  successHeading: "Almost there!",
  successLine1: "Confirm your subscription in the email we sent you.",
  successLine2: "You'll hear from us soon!",
}

const text = { de, en }
