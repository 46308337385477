import { localizeText } from "src/localization/utils"
import { META_DATA } from "src/metadata/constants"

export const Copyright = () => {
  const T = localizeText(text)

  return (
    <div className="opacity-75">
      © {META_DATA.address.companyName}. {T.allRightsReserved}.
    </div>
  )
}

const de = {
  allRightsReserved: "Alle Rechte vorbehalten",
}

const en: typeof de = {
  allRightsReserved: "All rights reserved",
}

const text = { de, en }
