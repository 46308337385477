import { CountrySelectPopover } from "src/components/common/CountrySelectPopover"
import { Copyright } from "src/components/layout/footer/Copyright"
import { CookieSettingsButton } from "src/integrations/lib/CookieSettingsButton"
import { localizeText } from "src/localization/utils"
import { StorefrontCountry } from "src/types/storefront"

export type FooterSettingsProps = {
  countryCode: string
  countries: StorefrontCountry[]
}

export const FooterSettings = ({ countryCode, countries }: FooterSettingsProps) => {
  const T = localizeText(text)

  return (
    <aside
      aria-label={T.settings}
      className="gap-4 flex flex-col items-center lg:items-start whitespace-nowrap text-sm"
    >
      <CountrySelectPopover countryCode={countryCode} countries={countries} />
      <CookieSettingsButton />
      <Copyright />
    </aside>
  )
}

const de = {
  settings: "Einstellungen",
}

const en: typeof de = {
  settings: "Settings",
}

const text = { de, en }
