"use client"

import { useIsomorphicLayoutEffect } from "ahooks"
import clsx from "clsx"
import { ChangeEventHandler, ComponentProps, useEffect, useId, useRef, useState } from "react"
import { EyeIcon, EyeOffIcon } from "src/components/icons"

export type InputProps = {
  type?: ComponentProps<"input">["type"]
  name: string
  label: string
  title?: string
  autoComplete: string
  topLabel?: string
  disabled?: boolean
  required?: boolean
  invalid?: boolean
  large?: boolean
  multiline?: boolean
  value: string | null | undefined
  onChange: ChangeEventHandler<HTMLInputElement>
}

export const Input = ({
  type,
  name,
  label,
  title,
  autoComplete,
  disabled,
  required,
  invalid,
  topLabel,
  large,
  multiline,
  value,
  onChange,
  ...props
}: InputProps) => {
  const id = useId()
  const ref = useRef<HTMLInputElement>(null)
  const [showPassword, setShowPassword] = useState(false)

  const forwardedType = type === "password" && showPassword ? "text" : type

  // Adding a new line briefly creates a scrollbar which results in visual
  // jitter. This can be avoided by using a layout effect.
  useIsomorphicLayoutEffect(() => {
    if (multiline && ref.current) {
      ref.current.style.height = "auto"

      const minLines = 3
      const minHeight = minLines * 20 + 20
      const optimalHeight = ref.current.scrollHeight
      const height = Math.max(minHeight, optimalHeight)

      ref.current.style.height = height + "px"
    }
  }, [multiline, value])

  const Element = multiline ? "textarea" : "input"

  return (
    <div className="flex flex-col w-full">
      {topLabel && (
        <label htmlFor={id} className="cursor-text mb-2 text-sm font-medium">
          {topLabel}
        </label>
      )}

      <div className="relative z-0 flex w-full text-sm">
        <Element
          {...props}
          ref={ref as any}
          id={id}
          type={forwardedType}
          name={name}
          title={title}
          placeholder=" "
          autoComplete={autoComplete}
          disabled={disabled}
          required={required}
          rows={3}
          value={value || ""}
          onChange={onChange as any}
          onPaste={onChange as any}
          className={clsx(
            "bg-gray-50 focus:outline-none focus:ring-0 focus:shadow-borders-interactive-with-active hover:bg-gray-100 block w-full pt-4 pb-1 mt-0 border rounded-3xl appearance-none",
            {
              "px-6 h-14 text-base": large,
              "px-4 h-11 text-sm": !large,
              "border-error": invalid,
            },
          )}
        />

        <label
          htmlFor={id}
          className={clsx(
            "text-subtle absolute flex items-center justify-center transition-all duration-300 pointer-events-none",
            {
              "px-6 top-4 text-base": large,
              "px-4 top-3 text-sm": !large,
            },
          )}
        >
          {label}
          {required && <span className="text-error">*</span>}
        </label>

        {type === "password" && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="text-subtle focus:outline-none focus:text-default top-3 absolute right-0 px-4 transition-all duration-150 outline-none"
          >
            {showPassword ? <EyeIcon /> : <EyeOffIcon />}
          </button>
        )}
      </div>
    </div>
  )
}
